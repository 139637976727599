import { LogoutOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import classNames from 'classnames'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  DashboardIcon,
  DefaultPic,
  HelpCircle,
  IntegrationsIcon,
  LogoMark,
  LogoType,
  MotionsIcon,
  NotificationBell,
  PremiumUnselectedIcon,
  WorkspaceIcon,
} from 'components/Navigation/NavigationIcons'
import { NotificationsPopUp } from 'components/NotificationsPopUp/NotificationsPopUp'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { MenuProps } from 'antd'
import type { LDEvaluationDetail } from 'launchdarkly-react-client-sdk'

import type { APIOptions } from 'models/admin'

interface NavigationProps {
  locationPathname: string
}

/**
 * Set the API override to the selected value.
 * @param {APIOptions} apiOptions The API options to set the override for
 * @param {string} value The value to set the override to
 */
export function updateApiOverride(apiOptions: APIOptions, value: string) {
  Object.entries(apiOptions[value] ?? {}).forEach(([key, value]: [string, string]) => {
    if (key && value) {
      localStorage.setItem(`API_URL_${key}`, value)
    }
  })
}

export const Navigation: React.FunctionComponent<NavigationProps> = observer(({ locationPathname }) => {
  const { actionsStore, notificationStore, userStore, integrationsStore } = useStore()
  const { getUnseenNotifications, unSeenNotifications } = notificationStore
  const { email, tenant } = userStore
  const { filteredToolboxItems, fetchActions } = actionsStore
  const { getIntegrations } = integrationsStore

  // Admin Tools
  const apiOptions = JSON.parse(localStorage.getItem('API_OPTIONS') || '{}') as APIOptions
  const [flags, setFlags] = useState<Record<string, LDEvaluationDetail>>({})
  const [showFeatureFlippers, setShowFeatureFlippers] = useState(false)
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('ADMIN_PANEL') === 'true')
  const ldClient = useLDClient()

  useDisplayErrorNotification(notificationStore)

  // Check if the admin panel is enabled by watching for changes in localStorage.
  useEffect(() => {
    const onStorage = () => {
      setIsAdmin(localStorage.getItem('ADMIN_PANEL') === 'true')
    }
    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  useEffect(() => {
    // Fetch the feature flags - ordering important so that it can invoke the API override before making any requests.
    if (isAdmin) {
      updateApiOverride(apiOptions, Object.keys(apiOptions)[0])
    }

    // Fetch the actions if they are not already fetched.
    if (!filteredToolboxItems?.actions?.length) {
      fetchActions().catch(console.error)
    }

    // Fetch the integrations.
    getIntegrations().catch(console.error)

    // Fetch the unseen notifications.
    getUnseenNotifications().catch(console.error)
  }, [])

  useEffect(() => {
    if (ldClient) {
      // Get all feature flag keys
      const allFlags = ldClient.allFlags()
      // Get the details for each flag
      for (const key of Object.keys(allFlags)) {
        const details = ldClient.variationDetail(key, false)
        allFlags[key] = {
          ...details,
        }
      }
      setFlags(allFlags)
    }
  }, [ldClient])

  const { search } = useLocation()

  let selectedKey
  switch (locationPathname?.split('/')[1]) {
    case 'dashboard':
      selectedKey = '1'
      break
    case 'motions':
      selectedKey = '2'
      break
    case 'email-templates':
      selectedKey = '3'
      break
    case 'integrations':
      selectedKey = '4'
      break
    default:
      selectedKey = '99'
      break
  }

  const isMotionRoute = locationPathname?.includes('motions/motion') && !locationPathname.includes('status')
  const isHelpRoute = locationPathname?.includes('/help-center')

  /** Should we show the Workspace link in the menu. */
  let shouldShowWorkspaceHub = false
  if (filteredToolboxItems?.actions) {
    // Currently there is only one Magnify action, so we can just check for that.
    shouldShowWorkspaceHub = filteredToolboxItems.actions.some((action) => action.name === 'magnify')
  }

  const subMenuItems: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <span className='ant-dropdown-menu-title-content' role='menuitem' id='account-settings-button'>
          <Link to='/account-settings/profile' data-testid='account-settings-slide-menu'>
            Account Settings
          </Link>
        </span>
      ),
    },
    {
      key: 2,
      label: (
        <Link to='/signout' data-testid='signout-slide-menu'>
          <span role='menuitem' id='sign-out-button' data-testid='sign-out-button'>
            <LogoutOutlined /> <span> Sign out</span>
          </span>
        </Link>
      ),
    },
  ]

  const mainMenuItems = [
    {
      key: '1',
      label: (
        <Link className='first-menu-item link-container' to={`/dashboard/${search}`} data-testid='dashboard-menu-side'>
          <DashboardIcon />
          <span className={`${isMotionRoute && 'collapsed-item'}`}>Dashboard</span>
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link className='second-menu-item link-container' to='/motions/' data-testid='motions-menu-side'>
          <MotionsIcon />
          <span className={`${isMotionRoute && 'collapsed-item'}`} data-testid='motions-menu-side-icon'>
            Motions
          </span>
        </Link>
      ),
    },
    shouldShowWorkspaceHub
      ? {
          key: '3',
          label: (
            <Link className='second-menu-item link-container' to='/email-templates/' data-testid='workspace-menu-side'>
              <WorkspaceIcon />
              <span className={`${isMotionRoute && 'collapsed-item'}`} data-testid='workspace-menu-side-icon'>
                Workspace
              </span>
            </Link>
          ),
        }
      : null,
    {
      key: '4',
      label: (
        <Link className='third-menu-item link-container' to='/integrations' data-testid='integrations-menu-side'>
          <IntegrationsIcon />
          <span className={`${isMotionRoute && 'collapsed-item'}`}>Integrations</span>
        </Link>
      ),
    },
  ]

  if (isAdmin) {
    mainMenuItems.push({
      key: '5',
      label: (
        <span className='feature-flags link-container' onClick={() => setShowFeatureFlippers((show) => !show)}>
          <PremiumUnselectedIcon />
          {showFeatureFlippers ? 'Hide Feature Flags' : 'Show Feature Flags'}
        </span>
      ),
    })
    if (Object.keys(apiOptions).length > 0) {
      mainMenuItems.push({
        key: '6',
        label: (
          <span className='admin-mock-api link-container'>
            <PremiumUnselectedIcon />
            Mock API:
            <select
              id='api-options'
              name='api-options'
              data-testid='api-options'
              onChange={(e) => {
                updateApiOverride(apiOptions, e.target.value)
              }}>
              {Object.keys(apiOptions).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </span>
        ),
      })
    }
    mainMenuItems.push({
      key: '7',
      label: (
        <span
          className='hide-admin-mode link-container'
          onClick={() => {
            setIsAdmin(false)
            setTimeout(
              () => {
                setIsAdmin(true)
              },
              5 * 60 * 1000,
            )
          }}>
          <PremiumUnselectedIcon />
          Hide Admin Mode (5 min)
        </span>
      ),
    })
    mainMenuItems.push({
      key: '8',
      label: (
        <span className='link-container'>
          <PremiumUnselectedIcon />
          Tenant ID: {tenant.id?.substring(24, 30)}
        </span>
      ),
    })
  }

  return (
    <Sider
      className={classNames('side-main-menu', {
        'collapsed-menu': isMotionRoute,
        'extended-menu': !isMotionRoute,
        'admin-menu': isAdmin,
      })}
      data-testid='side-main-menu'
      trigger={null}
      width={`${isMotionRoute ? '60px' : '260px'}`}>
      <Link
        className={`logo ${isMotionRoute ? 'logo-collapsed' : 'logo-expanded'}`}
        data-testid='logo-icon'
        to={`/dashboard/${search}`}>
        <LogoMark />
        {!isMotionRoute && <LogoType />}
      </Link>

      <Menu mode='inline' defaultSelectedKeys={[selectedKey]} selectedKeys={[selectedKey]} items={mainMenuItems} />

      <div className='bottom-container' data-testid='bottom-container'>
        <Link className={`bottom__help-center ${isHelpRoute ? 'selected' : ''}`} to='/help-center'>
          <HelpCircle />
          {!isMotionRoute && <span>Help center</span>}
        </Link>
        <div className='bottom__profile' data-testid='bottom-profile'>
          <Dropdown className='dropdown-menu' menu={{ items: subMenuItems }} placement='topRight'>
            <div className='ant-dropdown-link' data-testid='profile-dropdown'>
              <DefaultPic className='user-icon' />
              {isMotionRoute && unSeenNotifications.length ? (
                <NotificationBell className='notification-bell' />
              ) : (
                <span className='bottom__profile-user-info'>{email}</span>
              )}
            </div>
          </Dropdown>

          {!isMotionRoute && (
            <div className='bottom__profile__notifications'>
              <NotificationsPopUp />
            </div>
          )}
        </div>
      </div>
      {showFeatureFlippers && (
        <div className='admin-feature-flags-popover' data-testid='admin-feature-flags-popover'>
          {Object.keys(flags).map((key) => (
            <div key={key}>
              <code>{key}</code>:{' '}
              <span
                className={`flag-value-${flags[key].value ? 'true' : 'false'}`}>{`${JSON.stringify(flags[key].value)}`}</span>
              <p>
                <em>Reason:</em> {flags[key].reason?.errorKind || flags[key].reason?.kind}
              </p>
            </div>
          ))}
        </div>
      )}
    </Sider>
  )
})
