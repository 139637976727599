import { Alert, Form, Input, message, Modal, Spin } from 'antd'
import { updatePassword } from 'aws-amplify/auth'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'

import MFAModal from 'components/AccountSettings/Profile/MFAModal'
import { Button, Heading, IconCheckMark } from 'components/common'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { RoleEnum, RoleIds } from 'services/Utils/Role'
import useStore from 'store/useStore'

export const Profile = observer(() => {
  const { userStore } = useStore()
  const { get, isLoading, email, role, tenant, mfaEnabled, isSsoUser } = userStore
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [mfaModalVisible, setMfaModalVisible] = useState(false)

  useDisplayErrorNotification(userStore)

  useEffectOnce(() => {
    if (userStore.role !== RoleEnum.SuperAdmin && userStore.username && !userStore.role) {
      get(userStore.username).catch(console.error)
    }
  })

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    form.resetFields()
    setError(null)
    setIsModalVisible(false)
  }

  const [form] = Form.useForm()
  const changePassword = () => {
    form
      .validateFields()
      .then(async (values: { oldPassword: string; newPassword: string }) => {
        try {
          const { oldPassword, newPassword }: { oldPassword: string; newPassword: string } = values
          setLoading(true)
          await updatePassword({ oldPassword, newPassword })
          setError(null)
          form.resetFields()
          const successMessage = () => {
            void message.success('Password changed successfully', 4)
          }
          successMessage()
          setLoading(false)
          setIsModalVisible(false)
        } catch (error: unknown) {
          if (error instanceof Error) {
            void message.error(error.message, 4)
            setError(error)
          }
          setLoading(false)
        }
      })
      .catch((error: unknown) => {
        setError({
          message: 'It looks like there is an issue with your form. Check the highlighted fields and try again.',
          name: 'error',
        })
      })
  }

  return (
    <div className='container'>
      <div className='profile content' data-testid='profile-info'>
        <Heading level='3' variant='1'>
          Your profile
        </Heading>
        <Spin size='large' spinning={isLoading || loading}>
          <ul className='profile-details' data-testid='profile-details'>
            <li>
              <span className='label'>Organization Name</span>
              <p title={`${tenant.name} (${tenant.id})`}>{tenant.name}</p>
            </li>
            <li>
              <span className='label'>Email</span>
              <p>{email}</p>
            </li>
            <li>
              <span className='label'>Role</span>
              <p title={`${RoleIds[role]} (${role})`}>{RoleIds[role]}</p>
            </li>
            {!isSsoUser && (
              <>
                <li>
                  <span className='label'>Password</span>
                </li>
                <li>
                  <Button
                    text='Change password'
                    type='secondary'
                    style={{ width: '200px' }}
                    onClickHandler={showModal}
                  />
                </li>
                <li className='profile-details-mfa-label'>
                  <span className='label'>Multi-factor Authentication (MFA)</span>
                </li>
                <li>
                  {mfaEnabled ? (
                    <div className='profile-details-mfa-enabled-container'>
                      <IconCheckMark className='selected-data-field' data-testid='mfa-enabled-check-mark' />
                      <div className='profile-details-mfa-enabled-label'>Enabled</div>
                    </div>
                  ) : (
                    <Button
                      text='Setup MFA'
                      type='secondary'
                      style={{ width: '200px' }}
                      onClickHandler={() => setMfaModalVisible(true)}
                    />
                  )}
                </li>
              </>
            )}
          </ul>
          <Modal
            title='Change password'
            centered
            open={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <Button key='cancel' text='Cancel' htmlType='button' type='secondary' onClickHandler={handleCancel} />,
              <Button key='submit' text='Save' htmlType='submit' onClickHandler={changePassword} />,
            ]}>
            <Form
              form={form}
              className='change-password-form'
              name='change_password_form'
              data-testid='change-password-modal'
              labelCol={{
                span: 10,
              }}
              autoComplete='off'>
              <Form.Item
                label='Current password'
                name='oldPassword'
                rules={[
                  {
                    required: true,
                    message: 'Please input the current password',
                  },
                ]}
                colon={false}>
                <Input.Password autoComplete='off' data-testid='current-password' />
              </Form.Item>

              <Form.Item
                label='New password'
                name='newPassword'
                colon={false}
                rules={[
                  {
                    required: true,
                    message: 'Please input the new password',
                  },
                  {
                    min: 12,
                    message: 'Password must be at least 12 characters long',
                  },
                ]}>
                <Input.Password autoComplete='off' data-testid='new-password' />
              </Form.Item>
              <Form.Item
                label='Confirm new password'
                name='confirmPassword'
                colon={false}
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                  {
                    min: 12,
                    message: 'Password must be at least 12 characters long',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve()
                      }

                      return Promise.reject(new Error('The two passwords that you entered do not match!'))
                    },
                  }),
                ]}>
                <Input.Password autoComplete='off' data-testid='confirm-new-password' />
              </Form.Item>
              {error && (
                <Alert
                  className='error-password-message'
                  message={error.message}
                  type='error'
                  data-testid='error-password-message'
                />
              )}
            </Form>
          </Modal>
          {mfaModalVisible && (
            <MFAModal isModalOpen={mfaModalVisible} handleModalClose={() => setMfaModalVisible(false)} />
          )}
        </Spin>
      </div>
    </div>
  )
})
