import { Layout, notification } from 'antd'
import Spin from 'antd/es/spin'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import InsightsDetails from 'components/Insights/components/InsightsDetails'
import { Navigation } from 'components/Navigation/Navigation'
import Demo from 'configs/demo'
import Sandbox from 'configs/sandbox'
import AccountDetails from 'pages/AccountDetails'
import { AccountSettingsPage } from 'pages/AccountSettings/AccountSettings.page'
import { Dashboard } from 'pages/Dashboard/Dashboard'
import EmailTemplates from 'pages/EmailTemplates'
import FileAttachments from 'pages/FileAttachments'
import HelpCenter from 'pages/HelpCenter'
import { Integrations } from 'pages/Integrations/Integrations'
import { Motions } from 'pages/Motions/Motions'
import RedirectToMotion from 'pages/RedirectToMotion'
import useStore from 'store/useStore'

import type { LDContext } from 'launchdarkly-react-client-sdk'

const Main = () => {
  const ldClient = useLDClient()
  const location = useLocation()
  const { userStore } = useStore()
  const { username, email, tenant, role } = userStore
  const [isInitialized, setIsInitialized] = useState(false)
  const [isLaunchDarklyError, setIsLaunchDarklyError] = useState(false)

  useEffect(() => {
    if (ldClient && username && email && tenant && tenant.id) {
      const context: LDContext = {
        kind: 'multi',
        // Data-Core: https://github.com/Encore-Post-Sales/data-core/blob/fed7f35ac1127623d008e19d78fbf8043fc1b0df/packages/shared/src/lib/model/user.ts#L38
        user: {
          key: username,
          username: username,
          email: email,
          roleID: role,
          magnifyEmployee: email.endsWith('@magnify.io'),
        },
        // Data-Core: https://github.com/Encore-Post-Sales/data-core/blob/fed7f35ac1127623d008e19d78fbf8043fc1b0df/packages/shared/src/lib/model/tenant.ts#L53
        tenant: {
          key: tenant.id,
          id: tenant.id,
          name: tenant.name,
        },
      }

      ldClient
        .identify(context)
        .then((result) => {
          if (!result || Object.keys(result).length < 1) {
            return setIsInitialized(false)
          }
          setIsLaunchDarklyError(false)
          return setIsInitialized(true)
        })
        .catch((error) => {
          console.error('Failed to initialize LaunchDarkly', error)
          setIsLaunchDarklyError(true)
          setIsInitialized(true)
        })
    }
  }, [ldClient, username, email, tenant])

  if (!isInitialized) {
    return (
      <Layout>
        <div className='loading__wrapper'>
          <Spin size='large' spinning={true} />
        </div>
      </Layout>
    )
  }

  if (isLaunchDarklyError && (Demo.mockApiIsEnabled() || Sandbox.isEnabled())) {
    notification.error({
      message: 'Failed to initialize feature flags',
      description: 'Could not fetch feature flags. Please try again later.',
      duration: 5,
    })
  }

  return (
    <Layout>
      <Navigation locationPathname={location.pathname} />
      <Routes>
        <Route path='/motions/*' element={<Motions />} />
        <Route path='/dashboard/*' element={<Dashboard />} />
        <Route path='/integrations' element={<Integrations />} />
        <Route path='/integrations/add-oauth/:connectionId' element={<Integrations />} />
        <Route path='/account-settings/*' element={<AccountSettingsPage locationPathname={location.pathname} />} />
        <Route path='/help-center' element={<HelpCenter />} />
        <Route path='/insights/:target/:driverId' element={<InsightsDetails />} />
        <Route path='/accounts/:id' element={<AccountDetails />} />
        <Route path='/email-templates' element={<EmailTemplates />} />
        <Route path='/file-attachments' element={<FileAttachments />} />
        <Route path='/journeys/journey/*' element={<RedirectToMotion />} />
        <Route path='/journeys' element={<Navigate to='/motions' replace />} />
        <Route path='/*' element={<Navigate to='/dashboard' />} />
      </Routes>
    </Layout>
  )
}
Main.displayName = 'Main'

export default Main
